<template>
    
    <bs-popup id="mapping-aircraft-popup" @on-popup-closed="onPopupClosed" :auto-show="true" :isLarge="true" ref="form">
        <template v-slot:title>Review Vendor Invoice - {{model.vendorInvoiceNo}}</template>
        <template v-slot:content>
            <router-view></router-view>
            <bs-error-message :errors="errorMessages" />
            <div class="row">
                <div class="col-sm-6">

                    <div class="mb-3 row">
                        <label class="col-sm-4 form-label">Invoice Number</label>
                        <div class="col-sm-8">
                            <bs-plain-text v-model="model.vendorInvoiceNo" />
                        </div>
                    </div>

                    <div class="mb-3 row">
                        <label class="col-sm-4 form-label">Invoice Type</label>
                        <div class="col-sm-8">
                            <bs-plain-text v-model="model.invoiceTypeDescription" />
                        </div>
                    </div>

                    <div class="mb-3 row">
                        <label class="col-sm-4 form-label">Invoice Date</label>
                        <div class="col-sm-8">
                            <view-date v-model="model.invoiceDate" />
                        </div>
                    </div>                    

                    <div class="mb-3 row">
                        <label class="col-sm-4 form-label">Vendor Code</label>
                        <div class="col-sm-8">
                            <bs-plain-text v-model="model.vendorCode" />
                        </div>
                    </div>
                    
                    <div class="mb-3 row">
                        <label class="col-sm-4 form-label">Vendor Name</label>
                        <div class="col-sm-8">
                            <bs-plain-text v-model="model.vendorName" />
                        </div>
                    </div>
                </div>    

                <div class="col-sm-6">
                    <div class="mb-3 row">
                        <label class="col-sm-4 form-label">Submitted By</label>
                        <div class="col-sm-8">
                            <bs-plain-text v-model="model.submittedBy" />
                        </div>
                    </div>

                    <div class="mb-3 row">
                        <label class="col-sm-4 form-label">Submitted Date</label>
                        <div class="col-sm-8">
                            <view-date-time v-model="model.submittedOn" />
                        </div>
                    </div>

                    <div class="mb-3 row">
                        <label class="col-sm-4 form-label">Reviewed By</label>
                        <div class="col-sm-8">
                            <bs-plain-text v-model="model.reviewedBy" />
                        </div>
                    </div>
                    
                    <div class="mb-3 row">
                        <label class="col-sm-4 form-label">Reviewed Date</label>
                        <div class="col-sm-8">
                            <view-date v-if="model.reviewedBy" v-model="model.reviewedOn" />
                        </div>
                    </div>

                    <div class="mb-3 row">
                        <label class="col-sm-4 form-label">Invoice Status</label>
                        <div class="col-sm-8">
                            <bs-plain-text v-model="model.invoiceStatusDescription" />
                        </div>
                    </div>

                    <div class="mb-3 row" v-if="model.rejectReason != null && model.rejectReason != ''">
                        <label class="col-sm-4 form-label">Reject Reason</label>
                        <div class="col-sm-8">
                            <bs-plain-text v-model="model.rejectReason" />
                        </div>
                    </div>
                    
                </div>    
            </div>
            <div>Total Data : {{ this.totalData }}</div>
            <div class="row">
                <div class="table-responsive" style="overflow:scroll; height: 66vh;">
                    <table class="table table-stripped">
                        <thead>
                            <tr>
                                <th>Receipt Number</th>
                                <th>Location</th>
                                <th>Delivery Date</th>
                                <th>Flight Number</th>
                                <th>Route To (Opt.)</th>
                                <th>Aircraft Reg</th>
                                <th>Material</th>
                                <th>Qty</th>
                                <th>Unit</th>
                                <!-- <th>Qty in Gal</th>
                                <th>Qty in KL</th> -->
                                <th>Fuel Price in USD</th>
                                <th>Curr.</th>
                                <th>Throughput Fee (Opt.)</th>
                                <th>Hydrant Fee (Opt.)</th>
                                <th>Into Plane Fee (Opt.)</th>
                                <th>Other Fee (Opt.)</th>
                                <th>Fee in USD (Opt.)</th>
                                <th>Tax Amount in USD (Opt.)</th>
                                <th>Total Price after tax in USD</th>
                                <th>Local Currency Amount</th>
                                <th>Local Currency</th>
                                <th>Exchange Rate (Opt.)</th>
                                <th>SO Number</th>
                                <th>PR Number</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-if="model.invoiceDetails != null && model.invoiceDetails.length > 0">
                                <tr v-for="item in model.invoiceDetails" :key="item.id" 
                                    :class="item.errorMessageCreateSONumber != null || item.errorMessageCreatePRNumber != null ? 'table-danger' : ''"
                                    data-bs-toggle="tooltip" 
                                    :title="item.errorMessageCreateSONumber != null ? 'Failed Sync SO: ' + item.errorMessageCreateSONumber : (
                                        item.errorMessageCreatePRNumber != null ? 'Failed Sync PR: ' + item.errorMessageCreatePRNumber : ''
                                    )"
                                >
                                    <td>{{ item.receiptNumber }}</td>
                                    <td>{{ item.locationCode }}</td>
                                    <td><view-date v-model="item.deliveryDate" /></td>
                                    <td>{{ item.flightNumber }}</td>
                                    <td>{{ item.routeToCode }}</td>
                                    <td>{{ item.aircraftReg }}</td>
                                    <td>{{ item.materialCode }}</td>
                                    <td><bs-plain-number v-model="item.qty" /></td>
                                    <td>{{ item.unitCode }}</td>
                                    <!-- <td><bs-plain-number v-model="item.qtyInGal" /></td>
                                    <td><bs-plain-number v-model="item.qtyInKL" /></td> -->
                                    <td><bs-plain-number v-model="item.fuelPriceInUSD" /></td>
                                    <td>USD</td>
                                    <td><bs-plain-number v-model="item.throughputFee" /></td>
                                    <td><bs-plain-number v-model="item.hydrantFee" /></td>
                                    <td><bs-plain-number v-model="item.intoPlaneFee" /></td>
                                    <td><bs-plain-number v-model="item.otherFee" /></td>
                                    <td><bs-plain-number v-model="item.totalFee" /></td>
                                    <td><bs-plain-number v-model="item.taxAmountInUSD" /></td>
                                    <td><bs-plain-number v-model="item.priceAfterTaxInUSD" /></td>
                                    <td><bs-plain-number v-model="item.localCurrencyAmount" /></td>
                                    <td>{{ item.currencyCode }}</td>
                                    <td><bs-plain-number v-model="item.exchangeRate" /></td>
                                    <td>{{ item.soNumber }}</td>
                                    <td>{{ item.prNumber }}</td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>            
        </template>
        <template v-slot:footer>
            <template v-if="model.downloadVendorInvoice !== undefined && model.downloadVendorInvoice !== null">
                <button class="btn btn-sm btn-success" @click="downloadInvoice">Download Invoice</button>
            </template>
            
            <template v-if="model.invoiceStatusCode == 'WAITING_FOR_APPROVAL'">
                <button class="btn btn-sm btn-primary" v-show="isAuthApproveReject" @click="approve"><i class="bi bi-check-circle"></i> Approve</button>
                <button class="btn btn-sm btn-danger" v-show="isAuthApproveReject" @click="reject"><i class="bi bi-x-circle"></i> Reject</button>
            </template>

            <template v-if="needCreateSO">
                <button class="btn btn-sm btn-success" v-show="isCreateSO" @click="createSO">Create SO</button>
            </template>

            <button class="btn btn-sm btn-secondary" @click="close"><i class="bi bi-x-circle"></i> Cancel</button>
        </template>
    </bs-popup>
</template>

<script>
import ReviewInvoiceVendorService from "@/services/review-invoice-vendor-service.js";
import ViewDate from '../../../components/ViewDate.vue';
import BsPlainNumber from '../../../plugins/components/BsPlainNumber.vue';
import SecurityService from "@/services/security-service.js";

export default {
    components: { ViewDate, BsPlainNumber },
    data: function () {
        return {
            id: null,
            model: ReviewInvoiceVendorService.default(),
            errorMessages: null,
            isAuthApproveReject: false,
            isCreateSO: false,
            totalData: 0
        }
    },
    mounted() {
        let id = this.$route.params.id;
        this.id = id;
        this.loadData();
        this.checkRole();
    },
    computed: {
        needCreateSO: function() {
            if (this.model === undefined || this.model === null || this.model.id === undefined || this.model.id === null) 
            {
                return false;
            }
            
            if (this.model.invoiceStatusCode != 'APPROVED' && this.model.invoiceStatusCode != 'IN_PROGRESS'){
                return false;
            }

            if (this.model.invoiceTypeCode == 'CREDIT_NOTE') {
                return false;
            }

            if (this.model != null && this.model.invoiceDetails != null && this.model.invoiceDetails instanceof Array) {
                let totalPOorSOEmpty = this.model.invoiceDetails.filter(f => f.prNumber == null || f.prNumber == "" || f.soNumber == null || f.soNumber == "").length;
                if (totalPOorSOEmpty > 0) {
                    return true;
                }
            }

            return false;
        }
    },
    watch: {
        $route: function() {
            if (this.$route.path == `/review-invoice-vendor/detail/${this.id}`) {
                this.loadData();
            }
        }
    },
    methods: {
        async loadData() {
            let response = await ReviewInvoiceVendorService.getObject(this.id);
            this.model = response.data;
            this.totalData = this.model.invoiceDetails.length;

            if (response.status) 
            {
                this.errorMessages = null;
            }
            else 
            {
                this.errorMessages = response.errorMessages;
            }
        },
        onPopupClosed() {
            this.$router.push('/review-invoice-vendor');
        },
        close() {
            this.$refs.form.closePopup();
        },
        async approve() {
            try {
                this.$store.commit('progressCounterQueueIncrement');

                const response = await ReviewInvoiceVendorService.approve(this.model.id);
                if (!response.status) 
                {
                    this.errorMessages = response.errorMessages;
                }
                else 
                {
                    this.errorMessages = null;
                    this.loadData();
                }
            }
            finally {
                this.$store.commit('progressCounterQueueDecrement');
            }
        },
        async reject() {
            this.$router.push(`/review-invoice-vendor/detail/${this.model.id}/reject/${this.model.id}`);
        },
        async createSO() {
            try {
                this.$store.commit('progressCounterQueueIncrement');
                
                const response = await ReviewInvoiceVendorService.createSO(this.model.id);
                if (!response.status) {
                    this.errorMessages = response.errorMessages;
                }
                else {
                    this.errorMessages = null;
                    this.loadData();
                }
            } 
            finally {
                this.$store.commit('progressCounterQueueDecrement');
            }
        },
        async downloadInvoice() {
            try {
                this.$store.commit('progressCounterQueueIncrement');
            
                if (this.model !== undefined && this.model !== null && this.model.downloadVendorInvoice !== undefined && this.model.downloadVendorInvoice !== null) {
                    const aHref = document.createElement('a');
                    aHref.target = "_blank";
                    aHref.href = this.model.downloadVendorInvoice;
                    aHref.click();
                }
                else {
                    alert("Download link is not valid.");
                }
            }
            finally {
                this.$store.commit('progressCounterQueueDecrement');
            }
        },
        async checkRole() {
            const securityService = new SecurityService();

            if (await securityService.allowAccessAdmin("InvoiceApproval", "Create")) {
                this.isAuthApproveReject = true;
            } else {
                this.isAuthApproveReject = false;
            }

            if (await securityService.allowAccessAdmin("InvoiceCreateSO", "Create")) {
                this.isCreateSO = true;
            } else {
                this.isCreateSO = false;
            }
        }
    }
}
</script>

<style scoped>
    th, td 
    {
        white-space: nowrap;
    }
</style>